import { Container, Grid, Stack, Typography } from "@mui/material"
import React from "react"
import Logo from "../../assets/Logo.png"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <Container maxWidth="xl" sx={{ pt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Container sx={{ px: [0, 0, 4] }}>
            <Stack alignItems="center" direction="row">
              <img src={Logo} />
              <Typography
                variant="h5"
                display="flex"
                noWrap
                component="a"
                href="#"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  letterSpacing: ".1rem",
                  color: "inherit",
                  textDecoration: "none"
                }}
              >
                Chainblock{" "}
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    color: "#FA24FA"
                  }}
                >
                  {" "}
                  &nbsp;ART
                </Typography>
              </Typography>
            </Stack>

            <Typography
              variant="body1"
              sx={{
                my: 4,
                display: { xs: "none", md: "flex" },
                fontWeight: 300,
                // letterSpacing: '.1rem',
                color: "inherit"
              }}
            >
              Follow us on social media to stay updated on our
              <br />
              collections, new artists, new works and much more.
            </Typography>

            {/* Mobile View text */}
            <Typography
              variant="body1"
              sx={{
                my: 4,
                display: { xs: "block", md: "none" },
                fontWeight: 300,
                // letterSpacing: '.1rem',
                color: "inherit"
              }}
            >
              Follow us on social media to stay updated on our collections, new
              artists, new works and much more.
            </Typography>

            <Stack
              direction="row"
              sx={{ my: 5, display: { xs: "block", sm: "none" } }}
              spacing={2}
            >
              <Link
                to="/collection"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                Collection
              </Link>
              <a href="#" style={{ color: "#fff", textDecoration: "none" }}>
                Roadmap
              </a>
              <a href="#" style={{ color: "#fff", textDecoration: "none" }}>
                FAQs
              </a>
            </Stack>

            <Stack direction="row" sx={{ my: 5 }} spacing={2}>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                {" "}
                <TwitterIcon />
              </a>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </Stack>

            <Typography
              variant="body2"
              sx={{
                mt: 4,
                // display: { xs: 'none', md: 'flex' },
                fontWeight: 300,
                color: "inherit"
              }}
            >
              Copyright © 2023 More. All Rights Reserved.
            </Typography>
          </Container>
        </Grid>

        <Grid item xs={0} sm={4}>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#"
            sx={{
              mt: 2,
              display: { xs: "none", sm: "block" },
              fontWeight: 600,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            Quicks Links
          </Typography>

          <List sx={{ my: 2, display: { xs: "none", sm: "block" } }}>
            <ListItem disablePadding>
              <ListItemButton component={Link} to="/collection">
                <ListItemText primary="Collection" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component="a" href="#simple-list">
                <ListItemText primary="Roadmap" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="FAQs" />
              </ListItemButton>
            </ListItem>
          </List>

          <Stack
            direction="row"
            sx={{ my: 5, display: { xs: "none", sm: "block" } }}
            spacing={2}
          >
            <a href="#" style={{ color: "#fff", textDecoration: "none" }}>
              Privacy policy
            </a>
            <a href="#" style={{ color: "#fff", textDecoration: "none" }}>
              Terms of Use
            </a>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Footer
