import { Box, Button, Grid, Typography, Container } from "@mui/material"
import NftBackground from "../../../../assets/NftBackground.png"
import VirtualCard from "./virtualCard"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"

function VirtualWorldSection({data}) {

  const [soccerObject, setSoccerObject] = useState()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredSoccerCollection = data?.nfts?.filter(
        (item) => item["category"] === "64415c7c74603d1cb9dfd7b2"
      )
      if (filteredSoccerCollection.length > 0) {
        const randomIndex = Math.floor(Math.random() * filteredSoccerCollection.length);
        const randomObject = filteredSoccerCollection[randomIndex];
        setSoccerObject(randomObject);
      }
    }
  }, [data])

  return (
    <Box
      sx={{
        py: 5,
        backgroundImage: `url(${NftBackground})`,
        backgroundPosition: "bottom right",
        backgroundRepeat: "no-repeat"
      }}
    >
      <Container>
        <Grid sx={{ mt: 8, display: { xs: "block", sm: "flex" } }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              mt: [4, 0, 0, 0]
            }}
          >
            <VirtualCard image={soccerObject?.metadeta?.image} price={soccerObject?.price}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ px: [4, 4, 10] }}>
            <Typography
              variant="body1"
              sx={{
                my: 4,
                fontWeight: 700,
                textDecoration: "underline"
              }}
            >
              Author : {soccerObject?.name}
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mb: 4,
                fontWeight: 700
              }}
            >
              {soccerObject?.metadata?.name}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                my: 4,
                fontWeight: 300
              }}
            >
              {soccerObject?.metadata?.description}
            </Typography>
            <Button
              variant="contained"
              sx={{
                mb: 2,
                borderRadius: "20px",
                background: "linear-gradient(to right,#2600FC,#FA24FA)"
              }}
            >
              CONNECT WALLET
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

VirtualWorldSection.propTypes = {
  data: PropTypes.object
}

export default VirtualWorldSection
