import { Box, Grid } from "@mui/material"
import { TopNftCard, TopNftCardMobile } from "src/components"
import CollectionCarousel from "./collectionCarousel"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

function SameCollection({ data }) {
  const [topNfts, setTopNfts] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredArtCollection = data?.nfts?.filter(
        (item) => item["category"] === "6441486b74603d1cb9d0d659"
      )
      if (filteredArtCollection.length <= 4) {
        setTopNfts(filteredArtCollection)
      } else {
        const randomIndices = []
        while (randomIndices.length < 4) {
          const randomIndex = Math.floor(
            Math.random() * filteredArtCollection.length
          )
          if (!randomIndices.includes(randomIndex)) {
            randomIndices.push(randomIndex)
          }
        }
        const randomNfts = randomIndices.map(
          (index) => filteredArtCollection[index]
        )
        setTopNfts(randomNfts)
      }
    }
  }, [data])

  const viewDetails = (tokenAddress, tokenId) => {
    navigate(`/nft/${tokenAddress}/${tokenId}`)
  }

  return (
    <>
      <Grid
        container
        sx={{ mt: 5, display: { xs: "none", sm: "none", md: "flex" } }}
        spacing="20"
      >
        {topNfts?.map((v, i) => {
          return (
            <Grid key={i} item xs={12} sm={6}>
              <TopNftCard
                name={v?.metadata?.name}
                image={v?.metadata?.image}
                price={v?.price}
                tokenAddress={v?.token_address}
                tokenId={v?.token_id}
              />
            </Grid>
          )
        })}
        {/* <Grid item xs={12} sm={6}>
            <TopNftCard />
          </Grid> */}
      </Grid>

      {/* At mobile view */}
      <Grid
        container
        sx={{ my: 6, display: { xs: "block", sm: "block", md: "none" } }}
      >
        <Grid item xs={12}>
          <Box
            onClick={() =>
              viewDetails(topNfts[0]?.token_address, topNfts[0]?.token_id)
            }
          >
            <TopNftCardMobile
              name={topNfts[0]?.metadata?.name}
              image={topNfts[0]?.metadata?.image}
              price={topNfts[0]?.price}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <CollectionCarousel />
      </Box>
    </>
  )
}

SameCollection.propTypes = {
  data: PropTypes.object
}

export default SameCollection
