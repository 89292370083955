import React, { useEffect } from "react"
import { Footer, Navbar } from "src/components"

const SoccerWrapper = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default SoccerWrapper
SoccerWrapper.propTypes = {
  children: React.Children
}
