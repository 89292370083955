import React from "react"
import { Navigate, useRoutes } from "react-router-dom"
import { Root } from "src/components"
import LandingLayout from "src/layouts/landing"
import {
  Collection,
  Home,
  SingleNftPage,
  SoccerHome,
  SingleNftPageSoccer,
  Portfolio
} from "src/pages"
import { NotFound } from "src/pages/errors"
import SoccerWrapper from "src/pages/soccer/soccerWrapper"

const Routes = () => {
  const PublicRoutes = [
    { path: "404", element: <NotFound /> },
    {
      path: "soccer",
      elmement: <Root />,
      children: [
        {
          path: "",
          element: (
            <SoccerWrapper>
              <SoccerHome />
            </SoccerWrapper>
          )
        }
      ]
    },
    {
      path: "soccer",
      elmement: <Root />,
      children: [
        {
          path: "nft/:tokenAddress/:tokenId",
          element: (
            <SoccerWrapper>
              <SingleNftPageSoccer />
            </SoccerWrapper>
          )
        }
      ]
    },
    {
      path: "/",
      element: <LandingLayout />,
      children: [
        {
          path: "",
          elmement: <Root />,
          children: [{ path: "", element: <Home /> }]
        },
        {
          path: "",
          elmement: <Root />,
          children: [{ path: "collection", element: <Collection /> }]
        },
        {
          path: "",
          elmement: <Root />,
          children: [
            { path: "nft/:tokenAddress/:tokenId", element: <SingleNftPage /> }
          ]
        },
        {
          path: "",
          elmement: <Root />,
          children: [{ path: "portfolio", element: <Portfolio /> }]
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/404" />
    }
  ]
  return useRoutes([...PublicRoutes])
}

export default Routes
