import { Box } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"

function ChooseCard({ icon, title, text }) {
  return (
    <Card
      sx={{
        minWidth: 275,
        height: "300px",
        background: "transparent",
        borderRadius: "5px",
        border: "1px solid #E8E8E840"
      }}
    >
      <CardContent>
        <Box sx={{ my: 3 }}>
          <img src={icon} />
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          {title || ""}
        </Typography>
        <Typography variant="body2" sx={{ my: 3, color: "text.disabled" }}>
          {text || ""}
        </Typography>
      </CardContent>
    </Card>
  )
}

ChooseCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default ChooseCard
