import { Box, CircularProgress, Container } from "@mui/material"
import SameCollection from "./sameCollection"
import {
  BorderContainer,
  ConnectWallet,
  CustomTitle,
  MultiNFTS,
  Qans
} from "src/components"
import ProductsSection from "./otherCollection"
import PhaseSection from "./phaseSection"
import NftDetails from "./nftDetails"
import { useGetNftsQuery } from "src/services/endpoints/nft.api"

function SingleNftPage() {
  const { data, isLoading, isFetching } = useGetNftsQuery()

  return (
    <>
      <Container>
        <NftDetails />

        {/* Same collection section */}
        <Box sx={{ my: 14 }}>
          <CustomTitle mainText="In the" coloredText="same collection..." />
          {isLoading || isFetching ? (
            <Box sx={{ my: 6, textAlign: "center", alignItems: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <SameCollection data={data} />
          )}
        </Box>

        {/* Border */}
        <BorderContainer />

        {/* Soccer collection */}
        <Container
          sx={{ mt: 5, display: { xs: "none", sm: "none", md: "block" } }}
        >
          <CustomTitle mainText="Soccer" coloredText="collections" />
          {isLoading || isFetching ? (
            <Box sx={{ mt: 4, textAlign: "center", alignItems: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <ProductsSection
              categoryId="64415c7c74603d1cb9dfd7b2"
              data={data}
            />
          )}
        </Container>

        {/* Border */}
        <BorderContainer />

        {/* Phase Section */}
        <PhaseSection />

        {/* Connect Wallet */}
        <ConnectWallet />

        {/* QANS */}
        <Qans />
      </Container>
      <MultiNFTS />
    </>
  )
}

export default SingleNftPage
