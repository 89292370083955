import { Container, Paper } from "@mui/material"
import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Footer, Navbar } from "src/components"

const LandingLayout = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])
  return (
    <>
      <Container>
        <Navbar />
        <Paper sx={{ background: "transparent" }}>
          <Outlet />
        </Paper>
        <Footer />
      </Container>
    </>
  )
}

export default LandingLayout
