import { Box, Button, Typography } from "@mui/material"
import NftImage from "../../assets/nfts/NFTS.png"
import CustomTitle from "../customTitle"

function MultiNFTS() {
  return (
    <Box
      sx={{
        py: 5,
        backgroundImage: `url(${NftImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
      }}
    >
      <CustomTitle mainText="Start selling" coloredText="your works" />
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          textAlign: "center",
          mt: 3,
          fontWeight: 600
        }}
        data-aos="fade-down"
      >
        Are you an artist and want to sell your works? Contact us for the
        opportunity to sell them on our site.
      </Typography>

      <Box sx={{ textAlign: "center", mt: 6 }}>
        <Button
          variant="contained"
          sx={{
            mb: 4,
            borderRadius: "20px",
            background: "linear-gradient(to right,#2600FC,#FA24FA)"
          }}
          data-aos="fade-right"
        >
          CONTACT US
        </Button>
      </Box>
    </Box>
  )
}

export default MultiNFTS
