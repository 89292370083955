import CustomTitle from "../customTitle"
import { Box, Button, Container } from "@mui/material"
import Border from "../../assets/Border.png"
import Bitgo from "../../assets/wallet/bitgo.png"
import Coinbase from "../../assets/wallet/coinbase.png"
import Metamask from "../../assets/wallet/metamask.png"
import TrustWallet from "../../assets/wallet/trustwallet.png"
import Exodus from "../../assets/wallet/exodus.png"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { useEffect, useState } from "react"

function ConnectWallet() {
  const [showDots, setShowDots] = useState(false)

  useEffect(() => {
    const screenWidth = window.innerWidth
    setShowDots(screenWidth <= 768)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => {
    const screenWidth = window.innerWidth
    setShowDots(screenWidth <= 768)
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <>
      {/* Border */}
      <Container sx={{ mt: 10 }}>
        <Box sx={{ textAlign: "center" }}>
          <img width={50} src={Border} />
        </Box>
      </Container>

      {/* Connect Wallet */}
      <Container sx={{ mt: 5 }}>
        <CustomTitle mainText="Connect" coloredText="Wallet" />
        <Box sx={{ my: 8 }}>
          <Carousel
            autoPlaySpeed={2000}
            infinite={true}
            showDots={showDots}
            autoPlay={true}
            responsive={responsive}
            removeArrowOnDeviceType={[
              "superLargeDesktop",
              "desktop",
              "tablet",
              "largeMobile",
              "mobile"
            ]}
          >
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <img src={Bitgo} alt="wallet-img" />
            </Box>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <img src={Coinbase} alt="wallet-img" />
            </Box>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <img src={Metamask} alt="wallet-img" />
            </Box>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <img src={TrustWallet} alt="wallet-img" />
            </Box>
            <Box sx={{ mb: 4, textAlign: "center" }}>
              <img src={Exodus} alt="wallet-img" />
            </Box>
          </Carousel>
        </Box>

        <Box sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              mb: 4,
              borderRadius: "20px",
              background: "linear-gradient(to right,#2600FC,#FA24FA)"
            }}
          >
            CONNECT WALLET
          </Button>
        </Box>
      </Container>
    </>
  )
}

export default ConnectWallet
