import {
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  IconButton,
  Stack,
  Typography
} from "@mui/material"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import nftImage from "../../assets/nfts/Nft2.png"
import PropTypes from "prop-types"

function TopNftCardMobile({ name, image, price }) {
  return (
    <>
      <Card
        sx={{
          // maxWidth: 345,
          pl: 4,
          pt: 4,
          pb: 1,
          borderRadius: 2,
          boxShadow: "0 0 0 1px #2600FC, 0 0 0 2px #FA24FA",
          background: "transparent"
        }}
      >
        <CardActionArea>
          <CardMedia
            sx={{ height: 200 }}
            image={image || nftImage}
            title="nft-image"
          />
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" component="div">
                {name || "Abstract Artwork"} <br />
                <Typography variant="body2" component="span">
                  {price || "178.50"} ETH
                </Typography>
              </Typography>
              <IconButton
                aria-label="favourite"
                sx={{
                  background: "#EF466F",
                  borderRadius: 2,
                  px: 2,
                  color: "#fff"
                }}
              >
                <FavoriteBorderIcon />
              </IconButton>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  )
}

TopNftCardMobile.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string
}

export default TopNftCardMobile
