import { BrowserRouter } from "react-router-dom"
import Routes from "./routes"
import AOS from 'aos'

function App() {
  AOS.init()
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default App
