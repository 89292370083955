import { Card, CardContent, Stack, Typography } from "@mui/material"
import bitcoinImage from "../../../../assets/CryptoImages/bitcoin.png"
import graphImage from "../../../../assets/Graph BTC.png"

function CryptoCards() {
  return (
    <Card sx={{my:3}}>
      <CardContent sx={{}}>
        <Stack direction="row" justifyContent="space-between">
          <img src={bitcoinImage} alt="bitcoin-image" />
          <Typography variant="h6">
            Ripple <br />
            <Typography
              component="span"
              variant="caption"
              color="text.disabled"
            >
              BTC
            </Typography>
          </Typography>
          <img src={graphImage} alt="bitcoin-image" />
          <Typography variant="h6" sx={{ textAlign: "end" }}>
            $26928 <br />
            <Typography
              component="span"
              variant="caption"
              color="text.disabled"
            >
              2.09 BTC
            </Typography>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default CryptoCards
