/* eslint-disable max-len */
import { isRejectedWithValue } from "@reduxjs/toolkit"
import { updateErrorMessage } from "../slices/common"

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (
    // action?.meta?.arg?.endpointName?.toLowerCase() === 'signup' ||
    // action?.meta?.arg?.endpointName?.toLowerCase() === 'signin' ||
    // action?.meta?.arg?.endpointName?.toLowerCase() === 'forgotpassword' ||
    action?.meta?.arg?.endpointName?.toLowerCase() === "getuserfromtoken" ||
    action?.payload?.status === 400
  ) {
    return next(action)
  } else {
    if (isRejectedWithValue(action)) {
      if (action.payload.data)
        api.dispatch(
          updateErrorMessage({
            type: "error",
            message: action.payload.data.error || action.payload.data.FORM_ERROR
          })
        )
    }
  }
  return next(action)
}

export default rtkQueryErrorLogger
