import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Border from "../../assets/Border.png"
import { Box, Container } from "@mui/material"
import CustomTitle from "../customTitle"

function Qans() {
  const queries = [
    {
      question: "What is Chainblock’s NFT Collection?",
      answer: `Chainblock ART contains many works by different artists famous all over the world in a single site. ${(
        <br />
      )}
         Myfo, Gamolko, Terrana, are just some of the artists you can find on our site. `
    },
    {
      question: "How we can buy and invest NFT?",
      answer:
        " You can purchase the works you prefer directly from our site, by connecting your wallet, or by creating one."
    },
    {
      question: "Where we can buy and sell NFts?",
      answer:
        " Buying NFTs, selling them and exchanging them is simple thanks to dedicated marketplaces such as OpenSea and Rarible. Other famous platforms such as Coinbase and Binance, have opened up to NFTs and today allow anyone to create, sell, buy and deposit non-fungible tokens."
    },
    {
      question: "How can I contact you?",
      answer:
        " For any information or problem you can contact us at the email address info@chainblock.it. A member of our staff will respond to you as soon as possible."
    }
  ]
  return (
    <>
      {/* Border */}
      <Container sx={{ mt: 10 }}>
        <Box sx={{ textAlign: "center" }}>
          <img width={50} src={Border} />
        </Box>
      </Container>

      {/* Queries */}
      <Container sx={{ mt: 5, mb: 14 }}>
        <CustomTitle mainText="Your questions," coloredText="answered!" />
        <Box
          sx={{
            mx: [2, 4, 16],
            my: 8,
            py: [4, 8, 5],
            px: [4, 8, 5],
            border: "1px solid #fff",
            borderRadius: 5
          }}
          data-aos="flip-up"
          >
          {queries.map((v, i) => {
            return (
              <Accordion
                key={i}
                defaultExpanded={i==0}
                sx={{
                  // borderBottom: '1px solid #fff',
                  background: "transparent",
                  p: [0, 0, 3],
                  m: 0,
                  "&.Mui-expanded": {
                    m: 0
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontWeight: 600 }} variant="h6">
                    {v?.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ color: "text.disabled" }}>
                    {v?.answer}
                  </Typography>
                </AccordionDetails>
                <hr />
              </Accordion>
            )
          })}
        </Box>
      </Container>
    </>
  )
}

export default Qans
