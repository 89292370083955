import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Box, Button } from "@mui/material"
import { ProductCard } from "src/components"
import CallMadeIcon from "@mui/icons-material/CallMade"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

function ProductsSection({ categoryId, data }) {
  const [soccerCollection, setSoccerCollection] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredSoccerCollection = data?.nfts?.filter(
        (item) => item["category"] === categoryId
      )
      setSoccerCollection(filteredSoccerCollection)
    }
  }, [data, categoryId])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const getDetails = (data) => {
    navigate(`/soccer/nft/${data?.token_address}/${data?.token_id}`)
  }

  return (
    <>
      {/* Product Cards */}
      <Box sx={{ my: 8 }}>
        <Carousel
          autoPlaySpeed={2000}
          infinite={true}
          // showDots={true}
          autoPlay={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          {soccerCollection?.map((v, i) => {
            return (
              <Box
                onClick={() => getDetails(v)}
                key={i}
                sx={{ mx: 3, textAlign: "center" }}
              >
                <ProductCard
                  // id={v?._id}
                  name={v?.metadata?.name}
                  image={v?.metadata?.image}
                  price={v?.price}
                />
              </Box>
            )
          })}

          {/* <Box sx={{ mx: 3, textAlign: "center" }}>
              <ProductCard />
            </Box> */}
        </Carousel>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            my: 5,
            borderRadius: "20px",
            background: "linear-gradient(to right,#2600FC,#FA24FA)"
          }}
          endIcon={<CallMadeIcon />}
          component={Link}
          to="/soccer"
        >
          View collection
        </Button>
      </Box>
    </>
  )
}

ProductsSection.propTypes = {
  data: PropTypes.object,
  categoryId: PropTypes.string.isRequired
}

export default ProductsSection
