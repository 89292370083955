import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { CardActionArea } from "@mui/material"
import Product from "../../assets/Product.png"
import PropTypes from "prop-types"

function ProductCard({ name, image, price }) {
  return (
    <Card sx={{ maxWidth: 340, background: "transparent" }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="285px"
          width={"258px"}
          image={image || Product}
          alt="product"
          sx={{ borderRadius: "10px" }}
          loading="lazy"
        />
        <CardContent sx={{ textAlign: "center" }}>
          <Typography variant="body2" fontWeight={600}>
            {name || "#Boninsegna"}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 600, mt: 1 }}>
            € {price || "50,00"}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

ProductCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string
}

export default ProductCard
