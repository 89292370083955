import {
  Box,
  Button,
  Grid,
  Typography,
  Container,
  Stack,
  TextField,
  CircularProgress
} from "@mui/material"
import ProductCard from "./productCard"
import { useParams } from "react-router-dom"
import { useGetNftDetailsQuery } from "src/services/endpoints/nft.api"

function HeroSection() {
  
  const { tokenAddress, tokenId } = useParams()
  const { data, isLoading, isFetching } = useGetNftDetailsQuery({
    tokenAddress,
    tokenId
  })
  // console.log(data)

  return (
    <>
      <Box
        sx={{
          py: 5
        }}
      >
        <Container>
          {isLoading || isFetching ? (
            <CircularProgress color="inherit" />
          ) : (
            <Grid sx={{ display: { xs: "block", sm: "flex" } }}>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  mt: [4, 0, 0, 0],
                  px: 5,
                  alignSelf: "center"
                }}
              >
                <ProductCard image={data?.metadata?.image} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  px: [4, 4, 10],
                  py: 4,
                  borderLeft: "1px solid #fff",
                  borderRight: "1px solid #fff"
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    mb: 4,
                    fontWeight: 700
                  }}
                >
                  {data?.metadata?.name}{" "}
                  {/* <Typography variant="h5" component="span">
                    {data?.symbol || ""}
                  </Typography> */}
                </Typography>

                <Stack direction="row" sx={{ flexWrap: "nowrap" }} spacing={8}>
                  <Typography variant="h5">Price</Typography>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700
                    }}
                    color="#FA24FA"
                  >
                    {data?.price} ETH
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  sx={{ flexWrap: "nowrap", my: 4 }}
                  spacing={5}
                >
                  <TextField
                    id="outlined-basic"
                    label="Wallet Address"
                    variant="outlined"
                    sc={{
                      "& input:focus": {
                        borderColor: "#fff",
                        color: "#fff"
                      }
                    }}
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #fff",
                      color: "#fff"
                    }}
                  >
                    1
                  </Button>
                </Stack>

                <Button
                  variant="contained"
                  sx={{
                    my: 2,
                    borderRadius: "20px",
                    background: "#fff",
                    color: "#000",
                    width: "50%",
                    boxShadow: "0 4px 0px 2px #FA24FA"
                  }}
                >
                  BUY NFT
                </Button>

                <Typography
                  variant="caption"
                  component="div"
                  sx={{
                    my: 4,
                    fontWeight: 300
                  }}
                >
                  {data?.metadata?.description}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </>
  )
}

export default HeroSection
