import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import { Stack } from "@mui/material"

function ArtistCard({ image, name }) {
  return (
    <Card data-aos="flip-left" sx={{ maxWidth: 200, border: "0px", background: "transparent" }}>
      <CardMedia
        component="img"
        height="auto"
        image={image}
        alt="product"
        // sx={{ borderRadius: '10px',border:'2px solid #fff' ,}}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ fontWeight: 600, mt: 1 }}>
          {name}
        </Typography>
        <Typography variant="body2">Artist</Typography>
        <Stack
          direction="row"
          sx={{ mt: 3 }}
          justifyContent="center"
          spacing={2}
        >
          <a href="www.google.com" style={{ color: "#fff" }} target="_blank">
            <FacebookIcon />
          </a>
          <a href="www.google.com" style={{ color: "#fff" }} target="_blank">
            {" "}
            <TwitterIcon />
          </a>
          <a href="www.google.com" style={{ color: "#fff" }} target="_blank">
            <InstagramIcon />
          </a>
        </Stack>
      </CardContent>
    </Card>
  )
}

ArtistCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default ArtistCard
