import { Box, Button, Stack, Typography } from "@mui/material"
import CryptoCards from "./cryptoCards"

function Charts() {
  return (
    <>
      <Box sx={{ mx: 3, my: 5 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography variant="h6">Charts</Typography>
          <Button sx={{ p: 0, color: "#fff" }}>See All</Button>
        </Stack>

        {/* Crypto cards */}
        <CryptoCards />
        <CryptoCards />
        <CryptoCards />
      </Box>
    </>
  )
}

export default Charts
