import { Container, Grid, Box } from "@mui/material"
import { PhaseCard, CustomTitle } from "src/components"
import RoadMap from "../../../assets/roadMap/roadmapZero.png"
import RoadMapFifty from "../../../assets/roadMap/roadmapFifty.png"
import RoadMapHundred from "../../../assets/roadMap/roadmapHundred.png"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

function PhaseSection() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <Container sx={{ mt: 5 , display: { xs: "none", sm: "none", md: "flex" } }}>
      <CustomTitle mainText="Chainblock" coloredText="Roadmap" />
      <Grid
        container
        spacing={2}
        sx={{ my: 3}}
      >
        <Grid item xs={12} sm={6} md={4} data-aos="fade-right">
          <PhaseCard
            phase="01"
            icon={RoadMap}
            title="Choose"
            text="Choose the one you like from our unique collections. You are spoiled for choice."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} data-aos="fade-down">
          <PhaseCard
            phase="02"
            icon={RoadMapFifty}
            title="Connect"
            text="Connect your wallet or, if you don't have one yet, create it directly from our site in a few simple steps."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} data-aos="fade-left">
          <PhaseCard
            phase="03"
            icon={RoadMapHundred}
            title="Pay"
            text="Get your awesome NFT in seconds. Share your purchase on social pages and come back to visit us soon! "
          />
        </Grid>
      </Grid>

      <Box sx={{ my: 3, display: { xs: "block", sm: "none", md: "none" } }}>
        <Carousel
          autoPlaySpeed={2000}
          infinite={true}
          showDots={true}
          autoPlay={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          <PhaseCard
            phase="01"
            icon={RoadMap}
            title="Choose"
            text="Choose the one you like from our unique collections. You are spoiled for choice."
          />
          <PhaseCard
            phase="02"
            icon={RoadMapFifty}
            title="Connect"
            text="Connect your wallet or, if you don't have one yet, create it directly from our site in a few simple steps."
          />
          <PhaseCard
            phase="03"
            icon={RoadMapHundred}
            title="Pay"
            text="Get your awesome NFT in seconds. Share your purchase on social pages and come back to visit us soon! "
          />
        </Carousel>
      </Box>
    </Container>
  )
}

export default PhaseSection
