import { Container, Grid } from "@mui/material"
import { PhaseCard, CustomTitle } from "src/components"
import RoadMap from "../../../assets/roadMap/roadmapZero.png"
import RoadMapFifty from "../../../assets/roadMap/roadmapFifty.png"
import RoadMapHundred from "../../../assets/roadMap/roadmapHundred.png"

function PhaseSection() {
  return (
    <Container sx={{ mt: 5 }}>
      <CustomTitle mainText="Chainblock" coloredText="Roadmap" />
      <Grid container spacing={2} sx={{ my: 3 }}>
        <Grid item xs={12} sm={6} md={4} data-aos="fade-right">
          <PhaseCard
            phase="01"
            icon={RoadMap}
            title="Choose"
            text="Choose the one you like from our unique collections. You are spoiled for choice."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} data-aos="fade-down">
          <PhaseCard
            phase="02"
            icon={RoadMapFifty}
            title="Connect"
            text="Connect your wallet or, if you don't have one yet, create it directly from our site in a few simple steps."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} data-aos="fade-left">
          <PhaseCard
            phase="03"
            icon={RoadMapHundred}
            title="Pay"
            text="Get your awesome NFT in seconds. Share your purchase on social pages and come back to visit us soon! "
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default PhaseSection
