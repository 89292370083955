import { Box, Stack } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"

function PhaseCard({ phase, icon, title, text }) {
  return (
    <Card
      sx={{
        height: "300px",
        background: "transparent",
        borderRadius: "8px",
        border: "1px solid #E8E8E840"
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body2"
            sx={{ color: "linear-gradient(to right,#2600FC,#FA24FA)" }}
          >
            Phase {phase}
          </Typography>
          <Box>
            <img src={icon} width={50} />
          </Box>
        </Stack>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ my: 3, color: "text.disabled" }}>
          {text}
        </Typography>
      </CardContent>
    </Card>
  )
}

PhaseCard.propTypes = {
  phase: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default PhaseCard
