import {
  Card,
  CardMedia,
  CardContent,
  Stack,
  Typography,
  Box,
  Button,
  IconButton
} from "@mui/material"
import nftImage from "../../assets/nfts/Nft3.png"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

function ProductCardMobile({ name, image, price, tokenAddress, tokenId }) {
  const navigate = useNavigate()

  const viewDetails = () => {
    navigate(`/nft/${tokenAddress}/${tokenId}`)
  }

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Card
          sx={{
            // maxWidth: 345,
            px: 2,
            borderRadius: 3,
            background: "#303030"
          }}
        >
          <CardMedia
            sx={{ height: 200, mt: 2, borderRadius: 3 }}
            image={image || nftImage}
            title="nft-image"
            loading="lazy"
          />
          <CardContent sx={{ p: 2 }}>
            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
            >
              <Typography variant="h6" component="div">
                {name || " Spray Love"}
              </Typography>
              <Typography variant="body2" component="div">
                {price || "10"} ETH
              </Typography>
            </Stack>
            <Box sx={{ position: "absolute", bottom: "-15px" }}>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  sx={{
                    background: "#B7B4BB",
                    color: "#fff",
                    borderRadius: 4,
                    px: [4]
                  }}
                  onClick={() => viewDetails()}
                >
                  Discover
                </Button>
                <IconButton
                  aria-label="favourite"
                  sx={{
                    background: "#EF466F",
                    borderRadius: 1,
                    ml: 2,
                    color: "#fff"
                  }}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

ProductCardMobile.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  tokenAddress: PropTypes.string,
  tokenId: PropTypes.number
}

export default ProductCardMobile
