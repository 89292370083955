import { Box, Container } from "@mui/material"
import Border from "../../assets/Border.png"

function BorderContainer() {
  return (
    <>
      {/* Border */}
      <Container sx={{ mt: 10, display: { xs: "none", md: "block" } }}>
        <Box sx={{ textAlign: "center" }}>
          <img width={50} src={Border} />
        </Box>
      </Container>
    </>
  )
}

export default BorderContainer
