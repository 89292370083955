import { Box, Button, Stack, Typography } from "@mui/material"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import CollectionCard from "./collectionCard"

function CollectionCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }
  return (
    <>
      <Box sx={{ mx: 3, my: 5 }}>
        <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography variant="h6">Collection</Typography>
          <Button sx={{ p: 0, color: "#fff" }}>See All</Button>
        </Stack>
        <Carousel
          autoPlaySpeed={2000}
          infinite={true}
          // showDots={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          <CollectionCard />
          <CollectionCard />
          <CollectionCard />
        </Carousel>
      </Box>
    </>
  )
}

export default CollectionCarousel
