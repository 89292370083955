import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  styled,
  Button
} from "@mui/material"
import nftImage from "../../../../../assets/Product.png"
import User from "../../../../../assets/user/user3.png"
import User1 from "../../../../../assets/user/user4.png"
import User2 from "../../../../../assets/user/user5.png"
import User3 from "../../../../../assets/user/user6.png"
import PropTypes from "prop-types"

const StyledImg = styled("img")({
  position: "absolute",
  width: "40px"
})

function VirtualCard({image,price}) {
  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          px: 2,
          background: "linear-gradient(to bottom,#161925,#23395B)"
        }}
      >
        <Stack sx={{ my: 3 }} direction="row" justifyContent="space-between">
          <Box sx={{ position: "relative" }}>
            <StyledImg src={User} />
            <StyledImg src={User1} sx={{ left: "25px" }} />
            <StyledImg src={User2} sx={{ left: "53px" }} />
            <StyledImg src={User3} sx={{ left: "78px" }} />
          </Box>
          <Typography variant="caption" sx={{ color: "#B7B4BB" }}>
            Remaining Time <br />
            <Typography component="span" variant="body1" sx={{ color: "#fff" }}>
              09H: 11M : 07s
            </Typography>
          </Typography>
        </Stack>
        <CardMedia
          sx={{ height: 240, width: 300, borderRadius: 2 }}
          image={image || nftImage}
          title="nft image"
        />
        <CardContent>
          <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              Current bid
              <br />
              <Typography
                component="span"
                variant="h5"
                sx={{ color: "#fff", fontWeight: 700 }}
              >
                {price} ETH
              </Typography>
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: "20px",
                background: "linear-gradient(to right,#2600FC,#FA24FA)"
              }}
            >
              BUY NOW
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

VirtualCard.propTypes = {
  image: PropTypes.string,
  price: PropTypes.string,
}

export default VirtualCard
