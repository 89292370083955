import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { RESET_STATE_ACTION_TYPE } from "./actions/reset"
import { rtkQueryErrorLogger } from "./middlewares"
import { commonReducer, commonSlice } from "./slices/common"
import api from "src/services/api"
// import { authReducer, authSlice } from "./slices/auth"

const reducers = {
  [api.reducerPath]: api.reducer,
  [commonSlice.name]: commonReducer
  // [authSlice.name]: authReducer
}

const combinedReducer = combineReducers(reducers)

export const rootReducer = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {}
  }

  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      rtkQueryErrorLogger,
      api.middleware
    ])
})
