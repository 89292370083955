import {
  Stack,
  Card,
  Box,
  Button,
  Typography,
  CardMedia,
  CardContent
} from "@mui/material"
import PropTypes from "prop-types"

function ProductCardSoccer({image,title,subTitle,description,price}) {

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const truncatedText = truncateText(description, 100);

  return (
    <>
      <Card sx={{ display: "flex", p: [3], borderRadius: 4, background: "transparent" }}>
        <CardMedia
          component="img"
          sx={{ width: 300,height:400, borderRadius: 1 }}
          image={image}
          alt="nft-image"
          loading="lazy"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography variant="h5" sx={{fontWeight:800}}>{title}</Typography>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {subTitle}
            </Typography>
            <Typography component='div' variant="caption" sx={{ fontWeight: 600, my: 2 }}>
             {truncatedText}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                component="div"
              >
                Current Price
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                component="div"
                sx={{fontWeight:700}}
              >
               {price} ETH
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                my: 2,
                borderRadius: "20px",
                background: "#fff",
                color: "#000",
                width: "100%"
              }}
            >
              BUY NFT
            </Button>
          </CardContent>
        </Box>
      </Card>
    </>
  )
}

ProductCardSoccer.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired
  }

export default ProductCardSoccer
