// import qs from "query-string"
import api from "../api"

const Nfts = api.injectEndpoints({
  endpoints: (builder) => ({
    getNfts: builder.query({
      query: () => ({
        url: `/api/getnfts`
      }),
      providesTags: ["Nfts"]
    }),
    getNftDetails: builder.query({
      query: (q) => {
        return {
          url: `/api/getnft?token_address=${q?.tokenAddress}&token_id=${q?.tokenId}`
        }
      },
      providesTags: ["Nfts"]
    })
  }),
  overrideExisting: false
})

export const { useGetNftsQuery, useGetNftDetailsQuery } = Nfts
