import { Box, Button, Grid, Stack, Typography, styled } from "@mui/material"
import HeroImage from "../../../assets/HeroSection.png"
import User from "../../../assets/user/user.png"
import User1 from "../../../assets/user/user1.png"
import User2 from "../../../assets/user/user2.png"
import Hero from "../../../assets/hero.png"
import { Link } from "react-router-dom"

const StyledImg = styled("img")({
  position: "absolute",
  width: "40px"
})

function HeroSection() {
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("art-collection")
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <Stack sx={{ minHeight: "85vh", height: "1%" }}>
      {/* Hero Section */}
      <Grid container sx={{ mt: 8, height: "100%" }}>
        <Grid item xs={12} sm={6} sx={{ height: "100%", display: "flex" }}>
          <Stack data-aos="fade-right">
            <Typography
              variant="h1"
              sx={{
                fontWeight: 700,
                color: "inherit"
                // display: { xs: 'none', md: 'flex' },
                // letterSpacing: '.1rem',
              }}
            >
              High Quality NFT Collection
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                display: { xs: "block", sm: "none", md: "none" }
              }}
            >
              <img src={HeroImage} />
            </Box>
            <Typography
              variant="body1"
              sx={{
                my: 4,
                display: { xs: "none", md: "flex" },
                fontWeight: 300,
                // letterSpacing: '.1rem',
                color: "inherit"
              }}
            >
              Chainblock offers you many works of famous artists, divided into
              different collections.Choose yours.
            </Typography>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                sx={{
                  mb: 2,
                  borderRadius: "20px",
                  background: "linear-gradient(to right,#2600FC,#FA24FA)"
                }}
                onClick={() => scrollToNextSection()}
              >
                DISCOVER ALL
              </Button>
            </Stack>

            <Stack sx={{ mt: 5 }} direction="row" spacing={2}>
              <Box sx={{ position: "relative" }}>
                <StyledImg src={User} />
                <StyledImg src={User1} sx={{ left: "25px" }} />
                <StyledImg src={User2} sx={{ left: "53px" }} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  my: 4,
                  // display: { xs: 'none', md: 'flex' },
                  fontWeight: 500,
                  lineHeight: 1.2
                }}
                style={{ marginLeft: "105px" }}
              >
                47k+ <br />
                <span style={{ fontSize: "12px" }}>Community members</span>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: { xs: "none", sm: "flex", md: "flex" },
            background: `url(${Hero})`,
            backgroundSize: "contain",
            mt: [4, 0, 0, 0],
            height: "100%"
          }}
        >
          <Box data-aos="fade-left" sx={{ textAlign: "center" }}>
            <img src={HeroImage} />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default HeroSection
