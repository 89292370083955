import { Box, Grid, Stack, Typography, styled } from "@mui/material"
import HeroImage from "../../../assets/HeroSection.png"
import User from "../../../assets/user/user.png"
import User1 from "../../../assets/user/user1.png"
import User2 from "../../../assets/user/user2.png"
import Hero from "../../../assets/collectionHero.png"
import HeroCard from "./heroCard"

const StyledImg = styled("img")({
  position: "absolute",
  width: "40px"
})

function HeroSection() {
  return (
    <>
      {/* Hero Section */}
      <Grid container sx={{ mt: 8, display: { xs: "none", sm: "flex" } }}>
        <Grid item xs={12} sm={6} data-aos="fade-right">
          <Typography
            variant="h3"
            sx={{
              mb: 4,
              // display: { xs: 'none', md: 'flex' },
              fontWeight: 700,
              // letterSpacing: '.1rem',
              color: "inherit"
            }}
          >
            Art Collection
          </Typography>
          <Box
            sx={{
              textAlign: "center",
              display: { xs: "block", sm: "none", md: "none" }
            }}
          >
            <img src={HeroImage} />
          </Box>
          <Typography
            variant="body1"
            sx={{
              my: 4,
              display: { xs: "none", md: "flex" },
              fontWeight: 300,
              // letterSpacing: '.1rem',
              color: "inherit"
            }}
          >
            Chainblock offers you many works of famous artists, divided into
            different collections.Choose yours.
          </Typography>
          {/* <Button
            variant="contained"
            sx={{
              mb: 2,
              borderRadius: "20px",
              background: "linear-gradient(to right,#2600FC,#FA24FA)"
            }}
          >
            DISCOVER ALL
          </Button> */}
          <Stack sx={{ mt: 5 }} direction="row" spacing={2}>
            <Box sx={{ position: "relative" }}>
              <StyledImg src={User} />
              <StyledImg src={User1} sx={{ left: "25px" }} />
              <StyledImg src={User2} sx={{ left: "53px" }} />
            </Box>
            <Typography
              variant="body1"
              sx={{
                my: 4,
                // display: { xs: 'none', md: 'flex' },
                fontFamily: "green iguanainter",
                fontWeight: 500,
                lineHeight: 1.2
              }}
              style={{ marginLeft: "105px" }}
            >
              47k+ <br />
              <span style={{ fontSize: "12px" }}>Community members</span>
            </Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: { xs: "none", sm: "block", md: "block" },
            background: `url(${Hero})`,
            backgroundSize: "contain",
            mt: [4, 0, 0, 0]
          }}
        >
          <Box sx={{ textAlign: "-webkit-center" }}>
            <HeroCard />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default HeroSection
