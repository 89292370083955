import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import Marquee from "react-fast-marquee"

function TextBar({ mainText }) {
  return (
    <Box
      sx={{
        borderTop: "1px solid #fff",
        borderBottom: "1px solid #fff",
        py: 1
      }}
    >
      <Marquee>
        <Typography
          variant="h6"
          sx={{ fontWeight: 800, mx: 5, textShadow: "1px 1px 1px #FA24FA" }}
        >
          {mainText}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 800, mx: 5, textShadow: "1px 1px 3px #FA24FA" }}
        >
          {mainText}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 800, mx: 5, textShadow: "1px 1px 3px #FA24FA" }}
        >
          {mainText}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 800, mx: 5, textShadow: "1px 1px 3px #FA24FA" }}
        >
          {mainText}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 800, mx: 5, textShadow: "1px 1px 3px #FA24FA" }}
        >
          {mainText}
        </Typography>
      </Marquee>
    </Box>
  )
}

TextBar.propTypes = {
  mainText: PropTypes.string.isRequired
}

export default TextBar
