import { Box, CircularProgress, Container } from "@mui/material"
import {
  ConnectWallet,
  Qans,
  ArtistSection,
  MultiNFTS,
  BorderContainer,
  CustomTitle
} from "src/components"
import HeroSection from "./hero"
import ProductsSection from "./productsSection"
import ChooseSection from "./chooseSection"
import PhaseSection from "./phaseSection"
import { useGetNftsQuery } from "src/services/endpoints/nft.api"

const Home = () => {
  
  const { data, isLoading, isFetching } = useGetNftsQuery()
  // console.log(data)

  return (
    <>
      <Container>
        {/* Hero Section */}
        <HeroSection />

        {/* Border */}
        <BorderContainer />

        {/* Product Section */}
        <Container sx={{ mt: 5 }} id="art-collection">
          <CustomTitle mainText="Art" coloredText="collection" />
          {isLoading || isFetching ? (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <ProductsSection categoryId="6441486b74603d1cb9d0d659" data={data} />
          )}
        </Container>

        {/* Border */}
        <BorderContainer />

        {/* Product Section */}
        <Container sx={{ mt: 5 }}>
          <CustomTitle mainText="Soccer" coloredText="collection" />
          {isLoading || isFetching ? (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <ProductsSection categoryId="64415c7c74603d1cb9dfd7b2" data={data} />
          )}
        </Container>

        {/* Border */}
        <BorderContainer />

        {/* Product Section */}
        <Container sx={{ mt: 5 }}>
          <CustomTitle  mainText="Christmas" coloredText="collection" />
          {isLoading || isFetching ? (
            <Box sx={{ mt:4,textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <ProductsSection categoryId="6441486b74603d1cb9d0d659" data={data} />
          )}
        </Container>

        {/* Border */}
        <BorderContainer />

        {/* Choose Cards */}
        <ChooseSection />

        {/* Border */}
        <BorderContainer />

        {/* Phase Cards */}
        <PhaseSection />

        {/* Border */}
        <BorderContainer />

        {/* Artist Cards */}
        <ArtistSection />

        {/* Connect Wallet */}
        <ConnectWallet />

        {/* QANS */}
        <Qans />
      </Container>
      <MultiNFTS />
    </>
  )
}

export default Home
