import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import soccerPlayer from "../../../../assets/player.png"

function SubscriptionSection() {
  return (
    <>
      <Container
        sx={{
          background: "linear-gradient(to right,#9F25FF28,#48485F1A)",
          my: 8,
          borderRadius: 5
        }}
      >
        <Grid sx={{ display: { xs: "block", sm: "flex" } }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              mt: [4, 0, 0, 0],
              px: 5,
              py: 5
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                fontWeight: 700
              }}
            >
              Dont miss any NFTs drop
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{
                mb: 4
              }}
            >
              subscribe to always know about the best drops
            </Typography>

            <Stack
              direction="row"
              spacing={4}
              sx={{ flexWrap: "nowrap", my: 3 }}
            >
              <TextField
                id="outlined-basic"
                label="Enter Email"
                variant="outlined"
                sc={{
                  "& input:focus": {
                    borderColor: "#fff",
                    color: "#fff"
                  }
                }}
              />
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  background: "linear-gradient(to right,#2600FC,#FA24FA)"
                }}
              >
                SUBSCRIBE
              </Button>
            </Stack>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              mt: [4, 0, 0, 0],
              position: "relative",
              display: { xs: "none", sm: "none", md: "block" }
            }}
          >
            <Box sx={{ position: "absolute", top: "-121px" }}>
              <img src={soccerPlayer} alt="player-img" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default SubscriptionSection
