import { createTheme } from "@mui/material"
export const colors = {
  blue: {
    main: "#18269F",
    light: "#828DF8",
    ligher: "#d6d7e6",
    dark: "#111B73"
  },
  greyShades: [
    "#FFFFFF",
    "#FBFBFB",
    "#F8F8F8",
    "#F3F4F6",
    "#EBEBEE",
    "#E6E8F0",
    "#D1D5DB",
    "#65748B",
    "#6B7280",
    "#9CA3AF",
    "#4B5563",
    "#374151",
    "#1F2937",
    "#111827"
  ]
}
export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: "none"
        },
        sizeSmall: {
          padding: "6px 16px"
        },
        sizeMedium: {
          padding: "8px 20px"
        },
        sizeLarge: {
          padding: "11px 24px"
        },
        textSizeSmall: {
          padding: "7px 12px"
        },
        textSizeMedium: {
          padding: "9px 16px"
        },
        textSizeLarge: {
          padding: "12px 16px"
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
        sx: {
          ":focus, :focus-within, :focus-visible": {
            outline: "none!important"
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "32px 24px",
          "&:last-child": {
            paddingBottom: "32px"
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          justifyContent: "center",
          alignItems: "center"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: "0.75rem",
          fontWeight: 400
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "h6"
        },
        subheaderTypographyProps: {
          variant: "body2"
        }
      },
      styleOverrides: {
        root: {
          padding: "32px 24px"
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none"
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: "left",
          fontSize: "1rem",
          paddingLeft: "0.4em"
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          cursor: "pointer"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: "2.4rem",
          fontSize: "12px",
          backgroundColor: "transparent",
          borderColor: "none"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "2.4rem",
          fontSize: "12px",
          backgroundColor: "transparent",
          borderColor: "none"
        },
        input: {
          height: "0"
        },
        notchedOutline: {
          borderColor: "#E6E8F0"
        }
      }
    }
  },

  palette: {
    mode: "dark",
    neutral: {
      10: "#FBFBFB",
      50: "#FDFDFD",
      100: "#F8F8F8",
      200: "rgba(235, 235, 238, 0.4)",
      300: "#D1D5DB",
      400: "#9CA3AF",
      500: "#6B7280",
      600: "#4B5563",
      700: "#374151",
      800: "#1F2937",
      900: "#111827"
    },
    action: {
      active: "#6B7280",
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)"
    },
    background: {
      default: "#000000",
      paper: "#000"
      // paper: "#1F2937"
    },
    // divider: "#E6E8F0",
    primary: {
      main: "#18269F",
      light: "#828DF8",
      dark: "#111B73",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#10B981",
      light: "#3FC79A",
      dark: "#0B815A",
      contrastText: "#FFFFFF"
    },
    success: {
      main: "#57BE70",
      light: "rgba(165, 227, 0, 0.5)",
      dark: "#34A853",
      contrastText: "#FFFFFF"
    },
    info: {
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
      contrastText: "#FFFFFF"
    },
    warning: {
      main: "#FFA500",
      light: "rgba(251, 197, 42, 0.6)",
      dark: "#B27B16",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#D14343",
      light: "#DA6868",
      dark: "#922E2E",
      contrastText: "#FFFFFF"
    },
    text: {
      primary: "#fff",
      secondary: "#fff",
      disabled: "rgba(255, 255, 255, 0.48)"
    }
  },
  shape: {
    borderRadius: 8
  },
  // shadows: [
  //     "none",
  //     "0px 1px 1px rgba(23, 23, 23, 0.10), 0px 1px 2px rgba(23, 23, 23, 0.125)",
  //     "0px 1px 2px rgba(23, 23, 23, 0.20)",
  //     "0px 1px 4px rgba(23, 23, 23, 0.20)",
  //     "0px 1px 5px rgba(23, 23, 23, 0.20)",
  //     "0px 1px 6px rgba(23, 23, 23, 0.20)",
  //     "0px 5px 10px rgba(23, 23, 23, 0.06)",
  //     "0px 3px 6px rgba(23, 23, 23, 0.20)",
  //     "0px 2px 4px rgba(23, 23, 23, 0.10), 0px 4px 6px rgba(23, 23, 23, 0.10)",
  //     "0px 5px 10px 0px rgba(23, 23, 23, 0.20)",
  //     "0px 5px 10px 0px rgba(23, 23, 23, 0.15)",
  //     "0px 5px 10px 0px rgba(23, 23, 23, 0.06)",
  //     "0px 5px 10px rgba(23, 23, 23, 0.06)",
  //     "0px 5px 14px rgba(23, 23, 23, 0.20)",
  //     "0px 5px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 6px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 7px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 8px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 9px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 10px 15px rgba(23, 23, 23, 0.20)",
  //     "0px 12px 22px -8px rgba(23, 23, 23, 0.40)",
  //     "0px 13px 22px -8px rgba(23, 23, 23, 0.40)",
  //     "0px 14px 24px -8px rgba(23, 23, 23, 0.40)",
  //     "0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)",
  //     "0px 25px 50px rgba(23, 23, 23, 0.40)"
  //   ],
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: "Poppins",
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      lineHeight: 1.57
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase"
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66
    },
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
      lineHeight: 1.375
    },
    h2: {
      fontWeight: 600,
      fontSize: "3rem",
      lineHeight: 1.375
    },
    h3: {
      fontWeight: 600,
      fontSize: "2.25rem",
      lineHeight: 1.375
    },
    h4: {
      fontWeight: 500,
      fontSize: "2rem",
      lineHeight: 1.375
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.375
    },
    h6: {
      fontWeight: 500,
      fontSize: "1.125rem",
      lineHeight: 1.375
    }
  }
})
