import { Box, Container, Grid } from "@mui/material"
import { ChooseCard, CustomTitle } from "src/components"
import HugeIcon from "../../../assets/vector/hugeCollection.png"
import QualityIcon from "../../../assets/vector/highQuality.png"
import ResourceIcon from "../../../assets/vector/topResource.png"
import PaymentIcon from "../../../assets/vector/easyPayments.png"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

function ChooseSection() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <Container sx={{ mt: 5 }}>
      <CustomTitle mainText="Why" coloredText="choose us" />
      <Grid
        container
        spacing={2}
        sx={{ my: 3, display: { xs: "none", sm: "none", md: "flex" } }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-right" >
          <ChooseCard
            icon={HugeIcon}
            title="Huge collection"
            text="So many unique collections to buy to gobeyond the digital space."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-right" >
          <ChooseCard
            icon={QualityIcon}
            title="High quality"
            text="Our collectionsthey include unique specimens from world-famous artists."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-left" > 
          <ChooseCard
            icon={ResourceIcon}
            title="Top resource"
            text="Graphicscrafted with care in eachpixels, for unique NFTs."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} data-aos="fade-left" > 
          <ChooseCard
            icon={PaymentIcon}
            title="Easy payment"
            text="Easily connect your wallet or create it in just a few clicks, directly from our site."
          />
        </Grid>
      </Grid>

      <Box sx={{ my: 3, display: { xs: "block", sm: "none", md: "none" } }}>
        <Carousel
          autoPlaySpeed={2000}
          infinite={true}
          showDots={true}
          autoPlay={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          <ChooseCard
            icon={HugeIcon}
            title="Huge collection"
            text="So many unique collections to buy to gobeyond the digital space."
          />
          <ChooseCard
            icon={QualityIcon}
            title="High quality"
            text="Our collectionsthey include unique specimens from world-famous artists."
          />
          <ChooseCard
            icon={ResourceIcon}
            title="Top resource"
            text="Graphicscrafted with care in eachpixels, for unique NFTs."
          />
          <ChooseCard
            icon={PaymentIcon}
            title="Easy payment"
            text="Easily connect your wallet or create it in just a few clicks, directly from our site."
          />
        </Carousel>
      </Box>
    </Container>
  )
}

export default ChooseSection
