import { Box, CircularProgress, Container } from "@mui/material"
import HeroSection from "./hero"
import TopNftSection from "./topNftSection"
import {
  ArtistSection,
  BorderContainer,
  ConnectWallet,
  CustomTitle,
  MultiNFTS,
  Qans
} from "src/components"
import ProductsSection from "./productsSection"
import PhaseSection from "./phaseSection"
import { useGetNftsQuery } from "src/services/endpoints/nft.api"

const Collection = () => {
  const { data, isLoading, isFetching } = useGetNftsQuery()

  return (
    <>
      <Container>
        {/* Hero Section */}
        <HeroSection />

        {/* Top nft section */}
        {isLoading || isFetching ? (
          <Box sx={{ my: 6, textAlign: "center" }}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <TopNftSection data={data} />
        )}

        {/* Border */}
        {/* <BorderContainer /> */}

         {/* Product Section */}
         {/* <Container sx={{ mt: 5 }}>
          <CustomTitle mainText="See other" coloredText="collection" />
          {isLoading || isFetching ? (
            <Box sx={{ mt: 4, textAlign: "center" }}>
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <ProductsSection categoryId="6441486b74603d1cb9d0d659" data={data} />
          )}
        </Container> */}

        {/* Border */}
        <BorderContainer />

        {/* Phase Section */}
        <PhaseSection />

        {/* Connect Wallet */}
        <ConnectWallet />

        {/* Border */}
        <BorderContainer />

        {/* Artist Cards */}
        <ArtistSection />

        {/* QANS */}
        <Qans />
      </Container>
      <MultiNFTS />
    </>
  )
}

export default Collection
