import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"

function CustomTitle({ mainText, coloredText }) {
  return (
    <Box  sx={{ textAlign: "center" }}>
      <Typography
        variant="h4"
        component="span"
        sx={{
          display: "flex",
          fontFamily: "Poppins",
          fontWeight: 600,
          justifyContent: "center",
          flexWrap: "wrap",
          textShadow: "1px 1px 3px #FA24FA"
        }}
        data-aos="fade-up"
      >
        {mainText}
        <Typography
          variant="h4"
          component="span"
          sx={{
            fontFamily: "Poppins",
            fontWeight: 600,
            color: "#FA24FA",
            ml: 1,
            textShadow: "0px 0px 0px"
          }}
        >
          {coloredText}
        </Typography>
      </Typography>
    </Box>
  )
}

CustomTitle.propTypes = {
  mainText: PropTypes.string.isRequired,
  coloredText: PropTypes.string.isRequired
}

export default CustomTitle
