import {
  Card,
  CardMedia,
  CardContent,
  Stack,
  Typography,
  Box,
  Button,
  IconButton
} from "@mui/material"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import PropTypes from "prop-types"
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react"
import { useState } from "react"
import { ethers } from "ethers"

function DetailCardMobile({ name, image, price,handlePurchase }) {
  const [correctNetwork] = useState(5)
  const { isConnected, address, chainId } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  // async function handlePurchase() {
  //   if (isConnected) {
  //     try {
  //       if (chainId !== correctNetwork) {
  //         alert("Switch to Goerli network first")
  //         return
  //       }
  //       const metamaskProvider = new ethers.providers.Web3Provider(
  //         walletProvider
  //       )
  //       // const provider = new ethers.providers.JsonRpcProvider("https://polygon.llamarpc.com");
  //       const provider = new ethers.providers.JsonRpcProvider(
  //         "https://rpc-mumbai.maticvigil.com"
  //       )
  //       const metamaskSigner = metamaskProvider.getSigner()
  //       // const signer = new ethers.Wallet(process.env.REACT_APP_FEDE_PRIVATE_KEY, provider);
  //       const signer = new ethers.Wallet(
  //         process.env.REACT_APP_TESTNET_PRIVATE_KEY,
  //         provider
  //       )
  //       const nftContract = new ethers.Contract(
  //         data?.token_address,
  //         contractABI,
  //         signer
  //       )
  //       const owner = await nftContract.ownerOf(data?.token_id)
  //       console.log("owner", owner)
  //       if (owner.toLowerCase() == clientWallet.toLowerCase()) {
  //         const ethTransferTrx = await metamaskSigner.sendTransaction({
  //           to: clientWallet,
  //           value: ethers.utils.parseEther(String(data?.price), "ether")
  //           // value: ethers.utils.parseEther("0.001", "ether"),
  //           // gasLimit: 21000,
  //           // gasPrice: web3.utils.toHex(web3.utils.toWei('31', 'gwei')), // 31gwei as per 17April
  //         })
  //         let receipt = await ethTransferTrx.wait()

  //         if (receipt && receipt.status == 1) {
  //           transferNFT({ nftContract })
  //         } else {
  //           toast.error("ETH transfer failed")
  //         }
  //       } else {
  //         toast.error("Already sold")
  //       }
  //     } catch (err) {
  //       console.log("err in handlePurchase", err)
  //     }
  //   } else {
  //     toast.warn("Connect Wallet First")
  //   }
  // }
  // async function transferNFT({ nftContract }) {
  //   try {
  //     let nftTransferTrx = await nftContract.transferFrom(
  //       clientWallet,
  //       address,
  //       data?.token_id
  //     )
  //     let receipt = await nftTransferTrx.wait()
  //     if (receipt && receipt.status == 1) {
  //       let message = (
  //         <>
  //           <h5>NFT purchase success!</h5>
  //           <a
  //             href={`https://testnets.opensea.io/assets/mumbai/${data?.token_address}/${data?.token_id}`}
  //             rel="noreferrer"
  //             target="_blank"
  //           >
  //             Check NFT On Opensea
  //           </a>
  //         </>
  //       )
  //       toast.success(message)
  //       // alert("NFT Purchase Success!");
  //     } else {
  //       // alert("NFT Purchase failed");
  //       toast.error("NFT Purchase failed")
  //     }
  //   } catch (err) {
  //     console.log("err in transferNFT", err)
  //   }
  // }
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Card
          sx={{
            // maxWidth: 345,
            px: 2,
            borderRadius: 3,
            background: "#303030"
          }}
        >
          <CardMedia
            sx={{ height: 400, mt: 2, borderRadius: 3 }}
            image={image}
            title="nft-image"
          />
          <CardContent sx={{ p: 2 }}>
            <Stack
              direction="row"
              sx={{ mb: 2 }}
              justifyContent="space-between"
            >
              <Typography variant="h6" component="div">
                {name}
              </Typography>
              <Typography variant="body2" component="div">
                {price} ETH
              </Typography>
            </Stack>
            <Box sx={{ position: "absolute", bottom: "-15px" }}>
              <Stack direction="row" justifyContent="space-between">
                <Button
                onClick={()=> handlePurchase()}
                  sx={{
                    background: "linear-gradient(to right,#2600FC,#FA24FA)",
                    color: "#fff",
                    borderRadius: 4,
                    px: [4]
                  }}
                >
                  Buy now
                </Button>
                <IconButton
                  aria-label="favourite"
                  sx={{
                    background: "#EF466F",
                    borderRadius: 1,
                    ml: 2,
                    color: "#fff"
                  }}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

DetailCardMobile.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  handlePurchase: PropTypes.func.isRequired
}

export default DetailCardMobile
