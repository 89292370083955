import { Box, Stack, Typography } from "@mui/material"
import GraphImage from '../../assets/Graph.png'
import CollectionSection from "./collectionSection"
import Charts from "./charts"

function Portfolio() {

  return (
    <>
    <Box sx={{mx:3,borderRadius:2,p:2,background:'linear-gradient(to bottom,#6A35EE80,#5308FC66)'}}>
      <Typography variant="caption" sx={{mb:1}} component="div">
        Credit Balance
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" component="div" sx={{fontWeight:700}}>
          $25,215
        </Typography>
        <img src={GraphImage} alt="graph-image"/>
      </Stack>
    </Box>

    <CollectionSection />

    <Charts />
    </>
  )
}

export default Portfolio
