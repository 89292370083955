import { Button, Stack } from "@mui/material"
import { Link } from "react-router-dom"

function CollectionButton() {
  const collection = [
    "all collection",
    "soccer",
    "artwork",
    "christmas",
    "design"
  ]
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{ justifyContent: "center", my: 4, flexWrap: "wrap" }}
    >
      {collection.map((v, i) => {
        return (
          <Button
            key={i}
            variant="outlined"
            component={Link}
            to={v === "soccer" ? "/soccer" : "/collection"}
            sx={{
              color: "#fff",
              border: "1px solid #fff",
              borderRadius: 3,
              px: 4,
              textTransform: "uppercase",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
                boxShadow: "0 4px 0px 2px #FA24FA",
                border: 0
              },
              "&:active": {
                backgroundColor: "#000",
                color: "#fff",
                boxShadow: "0 4px 0px 2px #FA24FA"
              }
            }}
          >
            {v}
          </Button>
        )
      })}
    </Stack>
  )
}

export default CollectionButton
