import { Container, Grid } from "@mui/material"
import { ArtistCard, CustomTitle } from "src/components"
import Artist from "../../assets/artists/artist.png"
import Artist1 from "../../assets/artists/artist1.png"
import Artist2 from "../../assets/artists/artist2.png"
import Artist3 from "../../assets/artists/artist3.png"

function ArtistSection() {
  return (
    <Container sx={{ mt: 5, display: { xs: "none", md: "block" } }}>
      <CustomTitle mainText="Meet the" coloredText="Artist" />
      <Grid container spacing={2} sx={{ my: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <ArtistCard image={Artist} name="Myfo" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ArtistCard image={Artist1} name="Nicotra" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ArtistCard image={Artist2} name="Gamolko" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ArtistCard image={Artist3} name="Terrana" />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ArtistSection
