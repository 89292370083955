import SoccerHero from "./hero"
import { Box, CircularProgress, Container } from "@mui/material"
import TextBar from "./textBar"
import ProductCarousel from "./productCarousel"
import VirtualWorldSection from "./virtualWorldSection"
import CollectionButton from "./collectionButtons"
import { useGetNftsQuery } from "src/services/endpoints/nft.api"

function SoccerHome() {
  const { data, isLoading, isFetching } = useGetNftsQuery()

  return (
    <>
      <Container>
        <SoccerHero />
      </Container>

      <TextBar mainText="New Collection" />

      {isLoading || isFetching ? (
        <Box sx={{ my: 4, textAlign: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <ProductCarousel data={data} />
      )}

      <TextBar mainText="Our Marketplace" />

      {isLoading || isFetching ? (
        <Box sx={{ my: 4, textAlign: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <VirtualWorldSection data={data} />
      )}

      <TextBar mainText="Top Collection" />

      <CollectionButton />
    </>
  )
}

export default SoccerHome
