import { Stack, Typography } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import nftImage from "../../../../assets/nfts/NFT.png"

function HeroCard() {
  return (
    <>
      <Card sx={{ maxWidth: 345 }} data-aos="fade-left">
        <CardMedia sx={{ height: 240 }} image={nftImage} title="nft image" />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ textAlign: "start" }}
          >
            Alice in Wonderland
          </Typography>
          <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="body2">
              Auction time
              <br />
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                3h 1m 50s
              </Typography>
            </Typography>
            <Typography variant="body2">
              Current Bid
              <br />
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                0.15 ETH
              </Typography>
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  )
}

export default HeroCard
