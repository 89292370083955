import { Box, Grid } from "@mui/material"
// import SoccerHeroCard from "./heroCard"
import HeroImage from "../../../../assets/SoccerHero.png"
import CollectionImage from "../../../../assets/collectionCard.png"

function SoccerHero() {
  return (
    <>
      {/* Hero Section */}
      <Grid container sx={{ mt: 8, display: { xs: "block", sm: "flex" } }}>
        <Grid item xs={12} sm={7}>
          <img src={HeroImage} width="100%" />
        </Grid>

        <Grid item xs={12} sm={5}>
          {/* <Box>
            <SoccerHeroCard />
          </Box> */}
          <Box>
            <img src={CollectionImage} width="100%" alt="collection-img" />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default SoccerHero
