import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { Link as RouterLink } from "react-router-dom"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

const NotFound404 = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          mt: 10
        }}
      >
        <Container
          maxWidth="md"
          sx={{ border: "1px solid #fff", py: 10, borderRadius: 5 }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <ErrorOutlineIcon style={{ fontSize: 100 }} />
            <Typography align="center" variant="h4" sx={{ my: 3 }}>
              404: The page you are looking for isn’t here
            </Typography>
            <Typography align="center" variant="subtitle2">
              You either tried some shady route or you came here by mistake.
              Whichever it is, try using the navigation
            </Typography>
            <Button
              to="/"
              component={RouterLink}
              sx={{ mt: 3 }}
              variant="contained"
            >
              Go back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default NotFound404
