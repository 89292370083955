import { Box, CircularProgress } from "@mui/material"
import HeroSection from "./hero"
import ProductCarousel from "./productCarousel"
import SubscriptionSection from "./subscriptionSection"
import TextBar from "./textBar"
import { useGetNftsQuery } from "src/services/endpoints/nft.api"

function SingleNftPageSoccer() {
  const { data, isLoading, isFetching } = useGetNftsQuery()

  return (
    <>
      <HeroSection />

      <TextBar mainText="New Collection" />

      {isLoading || isFetching ? (
        <Box sx={{ my: 4, textAlign: "center", alignItems: "center" }}>
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <ProductCarousel data={data} />
      )}

      <TextBar mainText="Our Marketplace" />

      <SubscriptionSection />

      <TextBar mainText="Top Collection" />
    </>
  )
}

export default SingleNftPageSoccer
