import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { Box, Button } from "@mui/material"
import { ProductCard } from "src/components"
import CallMadeIcon from "@mui/icons-material/CallMade"
import PropTypes from "prop-types"
import ProductCardMobile from "src/components/ProductCardMobile"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

function ProductsSection({ categoryId, data }) {
  const [artcollection, setArtCollection] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredArtCollection = data?.nfts?.filter(
        (item) => item["category"] === categoryId
      )
      setArtCollection(filteredArtCollection)
    }
  }, [data, categoryId])

  // console.log(artcollection)

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const getDetails = (data) => {
    if (categoryId === "64415c7c74603d1cb9dfd7b2") {
      navigate(`soccer/nft/${data?.token_address}/${data?.token_id}`)
    } else {
      navigate(`nft/${data?.token_address}/${data?.token_id}`)
    }
  }

  return (
    <>
      {/* Product Cards */}

      {/* Desktop Product */}
      <Box sx={{ my: 8, display: { xs: "none", sm: "block" } }}>
        <Carousel
          autoPlaySpeed={5000}
          infinite={true}
          pauseOnHover={true}
          autoPlay={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          {artcollection?.map((v, i) => {
            return (
              <Box
                onClick={() => getDetails(v)}
                key={i}
                sx={{ mx: 3, textAlign: "center" }}
              >
                <ProductCard
                  // id={v?._id}
                  name={v?.metadata?.name}
                  image={v?.metadata?.image}
                  price={v?.price}
                />
              </Box>
            )
          })}
        </Carousel>
      </Box>

      {/* Mobile Product */}
      <Box sx={{ mt: 8, mb: 4, display: { xs: "block", sm: "none" } }}>
        <Carousel
          autoPlaySpeed={5000}
          infinite={true}
          autoPlay={true}
          responsive={responsive}
          // removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          pauseOnHover={true}
        >
          {artcollection?.map((v, i) => {
            return (
              <Box key={i} sx={{ mx: 3, height: "370px" }}>
                <ProductCardMobile
                  // id={v?._id}
                  name={v?.metadata?.name}
                  image={v?.metadata?.image}
                  price={v?.price}
                  tokenAddress={v?.token_address}
                  tokenId={v?.token_id}
                />
              </Box>
            )
          })}
        </Carousel>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <Button
          variant="contained"
          sx={{
            my: 5,
            borderRadius: "20px",
            background: "linear-gradient(to right,#2600FC,#FA24FA)"
          }}
          endIcon={<CallMadeIcon />}
          component={Link}
          to={
            categoryId === "64415c7c74603d1cb9dfd7b2" ? "soccer" : "collection"
          }
        >
          View collection
        </Button>
      </Box>
    </>
  )
}

ProductsSection.propTypes = {
  data: PropTypes.object,
  categoryId: PropTypes.string
}

export default ProductsSection
