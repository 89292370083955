import React, { useEffect, useState } from "react"
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Grid,
  ButtonBase
} from "@mui/material"
import DetailCardMobile from "./detailCardMobile"
// import nftImage from "../../../assets/nfts/Nft4.png"
import { useParams } from "react-router-dom"
import { useGetNftDetailsQuery } from "src/services/endpoints/nft.api"
// import singleNftImage from "../../../assets/nfts/singleNft.png"
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider
} from "@web3modal/ethers5/react"
import { ethers } from "ethers"
import { contractABI } from "src/GlobalContext/contractData"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function NftDetails() {
  const [showMore, setShowMore] = useState(false)
  const [clientWallet] = useState(
    // "0x80Fa19c59775494579bef9878D651bA270ec0140" // mainnet wallet
    "0x2868571CDdDA47E85b060B73EfA131f860CE4511" // testnet wallet
  )
  const [correctNetwork] = useState(11155111)
  const { isConnected, address, chainId } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const { tokenAddress, tokenId } = useParams()
  const { data, isLoading, isFetching } = useGetNftDetailsQuery({
    tokenAddress,
    tokenId
  })
  console.log("data", data)
  const [loader, setLoader] = useState(true);

  async function handlePurchase() {
    if (isConnected) {
      try {
        // start loading
        if (chainId !== correctNetwork) {
          alert("Switch to Sepolia network first")
          // end loading
          return
        }
        const metamaskProvider = new ethers.providers.Web3Provider(
          walletProvider
        )
        // const provider = new ethers.providers.JsonRpcProvider("https://polygon.llamarpc.com");
        const provider = new ethers.providers.JsonRpcProvider(
          // "https://polygon-mumbai-pokt.nodies.app"
          "https://eth-sepolia.g.alchemy.com/v2/WFqmrBFH8XlVECUEW_rIV3EClhCj0P5W"
        )
        const metamaskSigner = metamaskProvider.getSigner()
        // const signer = new ethers.Wallet(process.env.REACT_APP_FEDE_PRIVATE_KEY, provider);
        const signer = new ethers.Wallet(
          process.env.REACT_APP_TESTNET_PRIVATE_KEY,
          provider
        )
        const balance = await signer.getBalance();
        const balanceInETH = ethers.utils.formatEther(balance);
        console.log("owner wallet balance", balanceInETH);
        if(balanceInETH < 0.1){
          toast.error("Marketplace has insufficient ETH balance for NFT transfer");
          return;
        }
        const nftContract = new ethers.Contract(
          data?.token_address,
          contractABI,
          signer
        )
        const owner = await nftContract.ownerOf(data?.token_id)
        console.log("owner", owner)
        if (owner.toLowerCase() == clientWallet.toLowerCase()) {
          const ethTransferTrx = await metamaskSigner.sendTransaction({
            to: clientWallet,
            value: ethers.utils.parseEther(String(data?.price), "ether")
            // value: ethers.utils.parseEther("0.001", "ether"),
            // gasLimit: 21000,
            // gasPrice: web3.utils.toHex(web3.utils.toWei('31', 'gwei')), // 31gwei as per 17April
          })
          let receipt = await ethTransferTrx.wait()

          if (receipt && receipt.status == 1) {
            transferNFT(nftContract)
          } else {
            toast.error("ETH transfer failed")
            // end loading
          }
        } else {
          toast.error("Already sold")
          // end loading
        }
      } catch (err) {
        console.log("err in handlePurchase", err)
        // end loading
      }
    } else {
      toast.warn("Connect Wallet First")
      // end loading
    }
  }
  async function transferNFT(nftContract) {
    try {
      console.log("trx start");
      let nftTransferTrx = await nftContract.transferFrom(
        clientWallet,
        address,
        data?.token_id
      )
      console.log("nftTransferTrx", nftTransferTrx);
      let receipt = await nftTransferTrx.wait()
      console.log("trx end");
      console.log("receipt",receipt);
      if (receipt && receipt.status == 1) {
        // end loading
        let message = (
          <>
            <h5>NFT purchase success!</h5>
            <a
              href={`https://testnets.opensea.io/assets/sepolia/${data?.token_address}/${data?.token_id}`}
              rel="noreferrer"
              target="_blank"
            >
              Check NFT On Opensea
            </a>
          </>
        )
        toast.success(message)
        // alert("NFT Purchase Success!");
      } else {
        // end loading
        // alert("NFT Purchase failed");
        toast.error("NFT Purchase failed")
      }
      console.log("transferNFT end");
    } catch (err) {
      // end loading
      console.log("err in transferNFT", err)
    }
  }

  // if(loader){
  //   return <CircularProgress size={"100px"}  color="inherit" />
  // }
  return (
    <>
      <Box
        sx={{
          display: { xs: "none", sm: "flex", md: "flex" },
          mt: 10,
          flexWrap: "wrap-reverse"
        }}
        justifyContent="center"
        alignItems="center"
      >
        {isLoading || isFetching ? (
          <CircularProgress color="inherit" />
        ) : (
          <>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} sx={{ display: "flex" }}>
                <Card sx={{ p: 5 }}>
                  <CardContent>
                    <Typography variant="h4">{data?.metadata?.name}</Typography>
                    <Stack sx={{ my: 5 }}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h6">Current Price</Typography>
                        <Typography variant="h6" color="text.disabled">
                          {data?.price} ETH
                        </Typography>
                      </Stack>
                      <Typography variant="body2" mt={2}>
                        {showMore
                          ? data?.metadata?.description
                          : data?.metadata?.description?.slice(0, 250)}
                        {data?.metadata?.description.length > 250 &&
                          !showMore && (
                            <ButtonBase onClick={setShowMore}>
                              ... <i>showMore</i>
                            </ButtonBase>
                          )}
                      </Typography>
                    </Stack>
                    {/* <Stack>
                
                </Stack> */}
                    <Button
                      onClick={() => handlePurchase()}
                      variant="contained"
                      size="large"
                      sx={{
                        my: 2,
                        borderRadius: "20px",
                        background: "linear-gradient(to right,#2600FC,#FA24FA)",
                        px: [5, 8]
                      }}
                    >
                      Buy now
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <img
                  src={data?.metadata?.image}
                  height="600px"
                  alt="nft-image"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>

      {/* Nft details mobile */}
      <Box sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        {isLoading || isFetching ? (
          <CircularProgress color="inherit" />
        ) : (
          <DetailCardMobile
            name={data?.metadata?.name}
            price={data?.price}
            image={data?.metadata?.image}
            handlePurchase={handlePurchase}
          />
        )}
      </Box>
      <ToastContainer />
    </>
  )
}

export default NftDetails
