import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  snackBar: {
    type: null,
    message: null
  }
}

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    updateErrorMessage(state, action) {
      state.snackBar = action.payload
    }
  }
})

export const commonReducer = commonSlice.reducer
export const { updateErrorMessage } = commonSlice.actions
