import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { TopNftCard, TopNftCardMobile } from "src/components"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

function TopNftSection({ data }) {
  const [topNfts, setTopNfts] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredArtCollection = data?.nfts?.filter(
        (item) => item["category"] === "6441486b74603d1cb9d0d659"
      )
      setTopNfts(filteredArtCollection)
      // if (filteredArtCollection.length <= 4) {
      //   setTopNfts(filteredArtCollection)
      // } else {
      //   const randomIndices = []
      //   while (randomIndices.length < 4) {
      //     const randomIndex = Math.floor(
      //       Math.random() * filteredArtCollection.length
      //     )
      //     if (!randomIndices.includes(randomIndex)) {
      //       randomIndices.push(randomIndex)
      //     }
      //   }
      //   const randomNfts = randomIndices.map(
      //     (index) => filteredArtCollection[index]
      //   )
      //   setTopNfts(randomNfts)
      // }
    }
  }, [data])

  const viewDetails = (tokenAddress, tokenId) => {
    navigate(`/nft/${tokenAddress}/${tokenId}`)
  }

  return (
    <>
      <Grid
        container
        sx={{ my: 14, display: { xs: "none", sm: "flex", md: "flex" } }}
        spacing="20"
      >
        {topNfts?.map((v, i) => {
          // console.log(v);
          return (
            <Grid key={i} item xs={12} sm={12} md={6}>
              <TopNftCard
                name={v?.metadata?.name}
                image={v?.metadata?.image}
                price={v?.price}
                tokenAddress={v?.token_address}
                tokenId={v?.token_id}
              />
            </Grid>
          )
        })}

        {/* <Grid item xs={12} sm={6}>
          <TopNftCard />
        </Grid> */}
      </Grid>

      {/* At mobile view */}
      <Grid
        container
        sx={{ my: 14, display: { xs: "block", sm: "none", md: "none" } }}
      >
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">Art Collection</Typography>
            <Button
              sx={{
                p: 0,
                color: "text.disabled"
              }}
            >
              View all
            </Button>
          </Stack>
        </Grid>

        {topNfts?.map((v, i) => {
          // console.log(v);
          return (
            <Grid key={i} item xs={12} sx={{ mb: 3 }}>
              <Box onClick={() => viewDetails(v?.token_address, v?.token_id)}>
                <TopNftCardMobile
                  name={v?.metadata?.name}
                  image={v?.metadata?.image}
                  price={v?.price}
                />
              </Box>
            </Grid>
          )
        })}

        {/* <Grid item xs={12} sx={{ mb: 3 }}>
          <Box
            onClick={() =>
              viewDetails(topNfts[0]?.token_address, topNfts[0]?.token_id)
            }
          >
            <TopNftCardMobileer
              name={topNfts[0]?.metadata?.name}
              image={topNfts[0]?.metadata?.image}
              price={topNfts[0]?.price}
            />
          </Box>
        </Grid> */}
      </Grid>
    </>
  )
}

TopNftSection.propTypes = {
  data: PropTypes.object
}

export default TopNftSection
