import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import nftImage from "../../assets/nfts/NFT.png"
import { Stack, Card, Box, Button } from "@mui/material"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

function TopNftCard({ name, image, price, tokenId, tokenAddress }) {
  const navigate = useNavigate()

  const viewDetails = () => {
    navigate(`/nft/${tokenAddress}/${tokenId}`)
  }

  return (
    <>
      <Card sx={{ display: "flex", p: 3, borderRadius: 4 }}>
        <CardMedia
          component="img"
          sx={{ width: 200, borderRadius: 4 }}
          image={image || nftImage}
          alt="nft-image"
          loading="lazy"
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {name || "Alice in Wonderland"}
            </Typography>
            <Stack
              direction={{ md: "column", lg: "row" }}
              justifyContent="space-between"
              sx={{ mt: 2 }}
              spacing={{ sm:1 ,md: 1 }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                component="div"
              >
                Current Price
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                component="div"
              >
                {price || " 0.15"} ETH
              </Typography>
            </Stack>
            <Stack
              direction={{ md: "column", lg: "row" }}
              justifyContent="space-between"
              sx={{ mt: 4 }}
              spacing={{ sm:2,md: 2, lg: 4 }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  background: "linear-gradient(to right,#2600FC,#FA24FA)"
                }}
              >
                Buy now
              </Button>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "20px",
                  background: "#4f4f4f"
                }}
                onClick={() => viewDetails()}
              >
                View
              </Button>
            </Stack>
          </CardContent>
        </Box>
      </Card>
    </>
  )
}

TopNftCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.string,
  tokenId: PropTypes.number,
  tokenAddress: PropTypes.string
}

export default TopNftCard
