import {
  Stack,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material"
import nftImage from "../../../../../assets/Product.png"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import PropTypes from "prop-types"

function ProductCard({image}) {
  return (
    <>
      <Card
        sx={{
          maxWidth: 345,
          px: 2,
          background: "transparent"
        }}
      >
        <CardMedia
          sx={{ height: 240, width: 300, borderRadius: 2 }}
          image={image || nftImage}
          title="nft image"
        />
        <CardContent>
        <Stack direction="row" sx={{ my: 3,justifyContent:'center' }} spacing={2}>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <FacebookIcon />
              </a>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                {" "}
                <TwitterIcon />
              </a>
              <a
                href="www.google.com"
                style={{ color: "#fff" }}
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </Stack>
        </CardContent>
      </Card>
    </>
  )
}

ProductCard.propTypes = {
  image: PropTypes.string.isRequired
}

export default ProductCard
