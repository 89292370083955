import { Box, Container } from "@mui/material"
import ProductCardSoccer from "src/components/ProductCardSoccer"
import NftImage from "../../../../assets/Product.png"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

function ProductCarousel({ data }) {
  const [soccerCollection, setSoccerCollection] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.nfts) {
      const filteredArtCollection = data?.nfts?.filter(
        (item) => item["category"] === "64415c7c74603d1cb9dfd7b2"
      )
      setSoccerCollection(filteredArtCollection)
    }
  }, [data])

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    largeMobile: {
      breakpoint: { max: 600, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  const viewDetails = (v) => {
    navigate(`/soccer/nft/${v?.token_address}/${v?.token_id}`)
  }

  return (
    <>
      <Container
        sx={{
          background: "linear-gradient(to right,#9F25FF28,#48485F1A)",
          py: 2
        }}
      >
        <Carousel
          autoPlaySpeed={5000}
          infinite={true}
          pauseOnHover={true}
          autoPlay={true}
          responsive={responsive}
          showDots={true}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          {soccerCollection?.map((v, i) => {
            return (
              <Box
                onClick={() => viewDetails(v)}
                key={i}
                sx={{
                  mb: 2,
                  "&:hover": {
                    // backgroundColor: "#efefef",
                    cursor: "pointer"
                  }
                }}
              >
                <ProductCardSoccer
                  title={v?.metadata?.name}
                  image={v?.metadata?.image || NftImage}
                  price={v?.price}
                  subTitle="Milan - Inter"
                  description={v?.metadata?.description}
                />
              </Box>
            )
          })}

          {/* <Box sx={{mb:2}}>
            <ProductCardSoccer
              image={NftImage}
              title="HATELEY"
              subTitle="Milan - Inter"
              description="Award-winning illustrator with an international portfolio including."
              price="7"
            />
          </Box> */}
        </Carousel>
      </Container>
    </>
  )
}

ProductCarousel.propTypes = {
  data: PropTypes.object
}

export default ProductCarousel
