import {
  Stack,
  Typography,
  Card,
  CardContent,
  Box,
  styled,
  Button
} from "@mui/material"
import UserImage from "../../../../../assets/user/user1.png"
import VerifiedIcon from "@mui/icons-material/Verified"
import nftImage from "../../../../../assets/nfts/Nft5.png"
import nftImage2 from "../../../../../assets/nfts/Nft6.png"
import nftImage3 from "../../../../../assets/nfts/Nft7.png"

const StyledImg = styled("img")({
  width: "40px"
})

function CollectionCard() {
  return (
    <Card
      sx={{
        px: 2,
        border: "1px solid #fff",
        borderRadius: 2
      }}
    >
      <CardContent sx={{ px: 1 }}>
        <Stack sx={{ my: 3 }} direction="row" justifyContent="space-between">
          <Box>
            <Stack direction="row" spacing={2}>
              <StyledImg src={UserImage} />
              <Typography variant="body1">
                Bright Mark <VerifiedIcon color="success" />
                <br />
                <Typography component="span" variant="caption">
                  {" "}
                  64 items
                </Typography>
              </Typography>
            </Stack>
          </Box>

          <Button
            variant="outlined"
            sx={{ color: "#fff", border: "1px solid #fff" }}
            size="small"
          >
            Follow
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} justifyContent="center">
          <img src={nftImage} alt="nft-image" />
          <img src={nftImage2} alt="nft-image" />
          <img src={nftImage3} alt="nft-image" />
        </Stack>
      </CardContent>
    </Card>
  )
}

export default CollectionCard
