import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuIcon from "@mui/icons-material/Menu"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Logo from "../../assets/Logo.png"
import Stack from "@mui/material/Stack"
import FacebookIcon from "@mui/icons-material/Facebook"
import TwitterIcon from "@mui/icons-material/Twitter"
import InstagramIcon from "@mui/icons-material/Instagram"
import { Link } from "react-router-dom"
import {
  useWeb3ModalAccount, useWeb3Modal, createWeb3Modal, defaultConfig
} from "@web3modal/ethers5/react"
const projectId = "283689382f338c151011af5a0d2c0f18"

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://eth-mainnet.g.alchemy.com/v2/xRAqfimA2kyzAgJiN0d07JEqvFcj5xYW"
}
const goerli = {
  chainId: 5,
  name: "Goerli",
  currency: "ETH",
  explorerUrl: "https://goerli.etherscan.io/",
  rpcUrl: "https://eth-goerli.g.alchemy.com/v2/dDZPo6QwdOU0wf4zu2XObWQ7rBjeVOXv"
}
// const polygon = {
//   chainId: 137,
//   name: "Polygon",
//   currency: "MATIC",
//   explorerUrl: "https://polygonscan.com/",
//   rpcUrl: "https://polygon.llamarpc.com"
// }

// 3. Create modal
const metadata = {
  name: "Chainblock ART Marketplace",
  description: "A project of Chainblock",
  url: "https://chainblockart-marketplace.netlify.app/",
  icons: ["https://www.chainblocknft.com/images/logo/logo-svg.png"]
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  // chains: [mainnet],
  chains: [mainnet, goerli],
  projectId
})
const pages = ["home", "collection", "choose", "roadmap", "artists"]
const mobilePages = ["home", "collection", "portfolio"]
// const soccerPages = ['home','gallery','artists']


function Navbar() {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const handleConnectWallet = () => {
    try {
      open();
    } catch (err) { console.log("err in handleConnectWallet", err) }
  }

  return (
    <AppBar
      position="static"
      sx={{
        borderRadius: "none",
        background: "transparent",
        pt: 2
        // mb: 5,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <img src={Logo} />
          </Link>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Poppins",
              fontWeight: 600,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            Chainblock{" "}
            <Typography
              variant="h5"
              sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#FA24FA" }}
            >
              {" "}
              &nbsp;ART
            </Typography>
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#"
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Poppins",
              fontWeight: 600,
              letterSpacing: ".1rem",
              color: "inherit",
              textDecoration: "none"
            }}
          >
            Chainblock{" "}
            <Typography
              variant="h6"
              sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#FA24FA" }}
            >
              {" "}
              &nbsp;ART
            </Typography>
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "none", md: "flex" }
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                component={Link}
                to={page === "home" ? "/" : page}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  textTransform: "capitalize"
                }}
              >
                {page}
              </Button>
            ))}
            <Button
              onClick={() => handleConnectWallet()}
              variant="contained"
              sx={{
                my: 2,
                borderRadius: "20px",
                background: "linear-gradient(to right,#2600FC,#FA24FA)"
              }}
            >
              {isConnected
                ? `${address.slice(0, 6)}...${address.slice(37, 42)}`
                : `Connect Wallet`}
            </Button>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "flex-end",
              display: { xs: "flex", md: "none" }
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              {mobilePages.map((page) => (
                <MenuItem
                  sx={{ justifyContent: "center" }}
                  key={page}
                  onClick={handleCloseNavMenu}
                >
                  <Button
                    // textAlign="center"
                    sx={{ textTransform: "capitalize", color: "white" }}
                    component={Link}
                    to={page === "home" ? "/" : page}
                  >
                    {page}
                  </Button>
                </MenuItem>
              ))}
              <Stack
                direction="row"
                sx={{ justifyContent: "center", my: 3 }}
                spacing={2}
              >
                <a
                  href="www.google.com"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="www.google.com"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  {" "}
                  <TwitterIcon />
                </a>
                <a
                  href="www.google.com"
                  style={{ color: "#fff" }}
                  target="_blank"
                >
                  <InstagramIcon />
                </a>
              </Stack>
              <Button
                onClick={() => handleConnectWallet()}
                variant="contained"
                sx={{
                  mx: 2,
                  mb: 2,
                  borderRadius: "20px",
                  background: "linear-gradient(to right,#2600FC,#FA24FA)"
                }}
              >
                {isConnected
                  ? `${address.slice(0, 6)}...${address.slice(37, 42)}`
                  : `Connect Wallet`}
              </Button>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Navbar
